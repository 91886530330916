import { BeakerIcon, BuildingOfficeIcon, ChatBubbleLeftRightIcon, ClipboardDocumentCheckIcon, ClipboardDocumentListIcon, ComputerDesktopIcon, HeartIcon, InboxStackIcon, QrCodeIcon, UserCircleIcon } from '@heroicons/react/24/outline'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import ItemMenu from './ItemMenu'

function CardMenuKiosk() {
  const navigate = useNavigate()

  const dataMenu = [
    {
      title: 'Self Service',
      description: 'Pasien lama (umum) & belum booking',
      icon: <UserCircleIcon className='w-14 h-14' />,
      className: 'bg-pink-500/10 text-pink-500',
      onClick: () => navigate('/self-service-counter')
    },
    {
      title: 'Registrasi',
      description: 'Pasien baru & belum booking',
      icon: <ClipboardDocumentListIcon className='w-14 h-14' />,
      className: 'bg-green-500/10 text-green-500',
      onClick: () => navigate('registration-counter')
    },
    {
      title: 'Online Booking',
      description: 'Sudah booking via Web / WhatsApp',
      icon: <QrCodeIcon className='w-14 h-14' />,
      className: 'bg-primary/10 text-primary',
      onClick: () => navigate('/online-checkin-counter')
    },
  ]
  return (
    <div className='grid px-6 grid-cols-1 gap-6'>
      {dataMenu.map(item => {
        return <ItemMenu key={item.title} {...item} />
      })}
    </div>
  )
}

export default CardMenuKiosk